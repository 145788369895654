import React, { useState, useEffect } from 'react';
import styles from './InputManager.module.css';
import { addDefaultCredentials, getDefaultCredentials, updateDefaultCredentials } from '../../redux/Action/defaultCredActions';
import { toast } from 'react-toastify';

const DefaultCredential = () => {
    const [emails, setEmails] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [numberInput, setNumberInput] = useState('');

    const fetchData = async () => {
        try {
            const response = await getDefaultCredentials();
            if (response.success) {
                setEmails(response?.creds?.email);
                setNumbers(response?.creds?.number);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch initial data from the database
    useEffect(() => {
        fetchData();
    }, []);

    const handleEmailAdd = () => {
        if (emailInput && !emails.includes(emailInput)) {
            setEmails([...emails, emailInput]);
            setEmailInput('');
        }
    };

    const handleNumberAdd = () => {
        if (numberInput && !numbers.includes(numberInput)) {
            setNumbers([...numbers, numberInput]);
            setNumberInput('');
        }
    };

    const handleEmailRemove = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    const handleNumberRemove = (index) => {
        setNumbers(numbers.filter((_, i) => i !== index));
    };

    // const addDefaultCreds = async () => {
    //     try {
    //         const response = await addDefaultCredentials({ email: emails, number: numbers });
    //         if (response.success) {
    //             setNumbers(response?.creds?.number);
    //             setEmails(response?.creds?.email);
    //         }
    //     } catch (error) {
    //         console.error('Error saving credentials:', error);
    //     }
    // };

    const updateDefaultCreds = async () => {
        try {
            const response = await updateDefaultCredentials({ email: emails, number: numbers });
            if (response.success) {
                setNumbers(response?.creds?.number);
                setEmails(response?.creds?.email);
                toast.success("Default Credentials Updated")
            } else {
                toast.error("There is an error.")
            }
        } catch (error) {
            toast.error(error)
            console.error('Error updating credentials:', error);
        }
    };


    return (
        <div className={styles.container}>
            <h2>Email and Number Manager</h2>
            <div className={styles.sectionContainer}>

                {/* Email Input Box */}
                <div className={styles.box}>
                    <h3 className={styles.sectionTitle}>Manage Emails</h3>
                    <div className={styles.inputSection}>
                        <input
                            type="email"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            placeholder="Enter email"
                            className={styles.inputField}
                        />
                        <button onClick={handleEmailAdd} className={styles.addButton} aria-label="Add email">Add</button>
                    </div>
                    <div className={styles.list}>
                        {emails?.map((email, index) => (
                            <div key={index} className={styles.listItem}>
                                {email}
                                <button
                                    className={styles.removeButton}
                                    onClick={() => handleEmailRemove(index)}
                                    aria-label={`Remove email ${email}`}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Number Input Box */}
                <div className={styles.box}>
                    <h3 className={styles.sectionTitle}>Manage Numbers</h3>
                    <div className={styles.inputSection}>
                        <input
                            type="string"
                            value={numberInput}
                            onChange={(e) => setNumberInput(e.target.value)}
                            placeholder="Enter number"
                            className={styles.inputField}
                        />
                        <button onClick={handleNumberAdd} className={styles.addButton} aria-label="Add number">Add</button>
                    </div>
                    <div className={styles.list}>
                        {numbers?.map((number, index) => (
                            <div key={index} className={styles.listItem}>
                                {number}
                                <button
                                    className={styles.removeButton}
                                    onClick={() => handleNumberRemove(index)}
                                    aria-label={`Remove number ${number}`}
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Save and Update Buttons */}
            <div className={styles.buttonContainer}>
                {/* <button onClick={fetchData} className={styles.saveButton} aria-label="Save credentials">Refresh</button> */}
                <button onClick={updateDefaultCreds} className={styles.updateButton} aria-label="Update credentials">Update</button>
            </div>
        </div>
    );
};

export default DefaultCredential;
