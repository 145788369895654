import { toast } from "react-toastify";
import { getMethod, postMethod, putMethod } from "../../utils/apiServices";

export const updateDefaultCredentials = async (data) => {
    try {
        let response = await putMethod(`/admin/update/default/cred`, data);
        return response
    } catch (error) {
        toast.error(error)
    }
};

export const getDefaultCredentials = async () => {
    try {
        const response = await getMethod(`/admin/get/default/cred`);
        return response;
    } catch (error) {

    }
};

export const addDefaultCredentials = async (data) => {
    try {
        let response = await postMethod(`/admin/add/default/cred`, data);
        return response
    } catch (error) {

    }
};