/* eslint-disable */

import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Card, Spinner, Table } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import clsx from "clsx";
import styles from "./style/actionByHr.module.scss";
import DotPagination from "../../components/pagination/DotPagination.j";
import { getScreenHeight, getScreenWidth } from "../../utils/Utils";
import { asyncgetAllActionByHrList } from "../../redux/Action/actionByHrAction";
import { showDateColorBasedOnDate } from "../../utils/numberToArabicNumber";
import { FaAsterisk, FaHashtag } from "react-icons/fa";
import axios from "axios";
import { getCookie } from "../../utils/storage";

const ActionByHr = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { error, loading, success, actionByHrList, total } = useSelector((state) => state?.actionByHrSlice);
  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [screenHeight, setScreenHeight] = useState(getScreenHeight());
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [onSearchTextStatus, setOnSearchTextStatus] = useState(false);

  const toggle = () => setonSearch(!onSearch);

  //set status font color based on status
  const setFontColorBaseOnStatus = (status) => {
    if (status === "live" || status === "active") {
      return styles.greenColor;
    } else if (status === "draft" || status === "underReview" || status === "inactive") {
      return styles.yellowColor;
    } else if (status === "rejected" || status === "block" || status === "closed") {
      return styles.redColor;
    } else {
      return "";
    }
  };

  //set jobValidity color based on date
  const jobValidityCss = (jobValidity) => {
    if (showDateColorBasedOnDate(jobValidity) === "pastDate") {
      return styles.redColor;
    } else if (showDateColorBasedOnDate(jobValidity) === "sameDayDate") {
      return styles.yellowColor;
    } else {
      return styles.greenColor;
    }
  };

  const exportList = () => {
    let token = getCookie("_jwt");
    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${process.env.REACT_APP_API_URL}/admin/export/actionsByHr?page=${currentPage}&limit=10&search=${onSearchText ? onSearchText : ""}`,
      true
    );
    xhr.setRequestHeader("Authorization", token ? `Bearer ${token}` : "");
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      var blob = xhr.response;
      var tempEl = document.createElement("a");
      tempEl.style.display = "none";
      document.body.appendChild(tempEl);
      let url = window.URL.createObjectURL(blob);
      tempEl.href = url;
      tempEl.download = `Actions by Hr`;
      tempEl.click();
      window.URL.revokeObjectURL(url);
    };
    xhr.send();
  };

  // function to close the form modal

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    let timerOut = setTimeout(() => {
      dispatch(asyncgetAllActionByHrList(currentPage, onSearchText));
    }, 500);
    return () => {
      clearTimeout(timerOut);
    };
  }, [dispatch, currentPage, onSearchTextStatus, onSearchText]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
      setScreenHeight(getScreenHeight());
    }

    const resizeListener = () => handleResize();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Action by HR List"></Head>

      <Content>
        <BlockHead size="sm" className={"pb-0"}>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Actions by HR List
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {total} </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <div className="w-100 d-flex justify-content-end align-items-center">
          <div>
            <div style={{ color: "#FF7D3D" }}>
              <FaAsterisk color="#FF7D3D" />&nbsp;This job is &nbsp; <b> open for conflict affected candidates</b>
            </div>
            <div style={{ color: "#6576ff" }} className="w-100 d-flex justify-content-end align-items-center">
              <FaHashtag color="#3b47a7" />&nbsp;This is an &nbsp; <b> internship</b>
            </div>
          </div>
          <button className="btn btn-primary mx-2" onClick={exportList}>
            Export
          </button>
        </div>

        <Block style={{ marginTop: "15px" }}>
          {/* filter  */}

          {/*search bar */}

          <Card className="card-bordered">
            <div className="card-inner-group">
              <div
                className="card-inner p-0"
                style={{
                  width: "100%",
                }}
              >
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group">
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <div className="toggle-wrap">
                            <Button
                              className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                              onClick={() => updateTableSm(true)}
                            >
                              <Icon name="menu-right"></Icon>
                            </Button>
                            <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                              <ul className="btn-toolbar gx-1">
                                <li className="toggle-close">
                                  <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                    <Icon name="arrow-left"></Icon>
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                    <div className="card-body">
                      <div className="search-content">
                        {onSearchText ? (
                          <Button
                            className={clsx("search-back btn-icon toggle-search active", styles.lightRed)}
                            onClick={() => {
                              setCurrentPage(1);
                              setSearchText("");
                              setOnSearchTextStatus(!onSearchTextStatus);
                            }}

                          // color="danger"
                          >
                            <Icon name="cross"></Icon>
                          </Button>
                        ) : null}
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control "
                          placeholder="Enter HR name/Company name/Job title"
                          value={onSearchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            setCurrentPage(1);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              setCurrentPage(1);
                              setOnSearchTextStatus(!onSearchTextStatus);
                            }
                          }}
                        />
                        <Button
                          className="search-submit btn-icon"
                          onClick={(e) => {
                            setCurrentPage(1);
                            setOnSearchTextStatus(!onSearchTextStatus);
                            // searchBasedOnHrNameCompanyNameJobTitle(e);
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Spinner type="grow" color="dark" />
                  </div>
                ) : error ? (
                  "something went wrong..."
                ) : success ? (
                  <div className={clsx(styles.tableScroll)}>
                    {/* res table */}
                    <Table responsive>
                      <thead>
                        <tr className={clsx(styles.postTableHeading, "text-center")}>
                          <th size="12">
                            {" "}
                            <span>Company</span>
                          </th>
                          <th className="mdTable text-center">
                            {" "}
                            <span>{"HR"}</span>
                          </th>
                          <th className="mdTable text-center">
                            {" "}
                            <span>Job Title</span>
                          </th>
                          <th className="mdTable text-center">
                            {" "}
                            <span>Valid Till</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Created</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Go Live</span>
                          </th>
                          <th className="mdTable text-center">
                            {" "}
                            <span>Status</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Candidates</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Shortlisted</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Unlocks</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Interviews</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Rejected</span>
                          </th>
                          <th className="mdTable text-center">
                            <span>Last Connected</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className={clsx(styles.postTablePara)}>
                        <>
                          {actionByHrList?.length ? (
                            actionByHrList?.map((item, index) => {
                              return (
                                <tr key={index} className={clsx(styles.postTableHeading)}>
                                  <td className="text-center">
                                    <Link to={`/action-by-hr-company-details/${item?.companyId}?p=${currentPage}`}>
                                      <span className={clsx(styles.fontSize10)}>
                                        {item?.companyName ? item?.companyName : ""}
                                      </span>
                                    </Link>
                                  </td>
                                  <td className={clsx("mdTable text-center")}>
                                    <Link to={`/action-by-hr-hr-details/${item?.hrId}?p=${currentPage}`}>
                                      <span className={styles.fontSize10}>{item?.hrName ? item?.hrName : ""}</span>
                                    </Link>
                                  </td>
                                  <td
                                    className={clsx(
                                      "mdTable text-center",

                                      styles.minWidth150
                                    )}
                                  >
                                    <Link to={`/action-by-hr-job-details/${item?.jobId}?p=${currentPage}`}>
                                      <span className={clsx("tb-sub")}>
                                        <span>
                                          {item?.conflict_affected_allowed && <span className="d-inline">
                                            <FaAsterisk color="#FF7D3D" />
                                          </span>}
                                          {item?.isInternship && <FaHashtag color="#3b47a7" />}
                                        </span>
                                        &nbsp;{item?.jobTitle ? item?.jobTitle : 0}</span>
                                    </Link>
                                  </td>
                                  <td className={clsx("mdTable text-center", styles.minWidth100)}>
                                    <span
                                      className={clsx(
                                        "tb-sub",
                                        jobValidityCss(moment(item.jobValidity)?.utc()?.tz("Africa/Cairo"))
                                      )}
                                    >
                                      {item?.jobValidity
                                        ? moment(item.jobValidity)?.utc()?.tz("Africa/Cairo")?.format("DD MMM YYYY")
                                        : ""}
                                    </span>
                                  </td>
                                  <td className={clsx("mdTable text-center", styles.minWidth100)}>
                                    <span className="tb-sub">
                                      {item?.dateCreated
                                        ? moment(item.dateCreated)?.utc()?.tz("Africa/Cairo")?.format("DD MMM YYYY ")
                                        : ""}
                                    </span>
                                  </td>
                                  <td className={clsx("mdTable text-center", styles.minWidth100)}>
                                    <span className="tb-sub">
                                      {item?.goLiveDate
                                        ? moment(item.goLiveDate)?.utc()?.tz("Africa/Cairo")?.format("DD MMM YYYY ")
                                        : ""}
                                    </span>
                                  </td>
                                  <td className={clsx("mdTable text-center")}>
                                    <span className={clsx("tb-sub", setFontColorBaseOnStatus(item?.jobStatus))}>
                                      {item?.jobStatus === "inactive" ? "paused" : item?.jobStatus}
                                    </span>
                                  </td>

                                  <td className="mdTable text-center ">
                                    <span className={clsx("tb-sub", item?.numberOfCandidates ? styles.greenColor : "")}>
                                      {item?.numberOfCandidates ? item?.numberOfCandidates : 0}
                                    </span>
                                  </td>
                                  <td className="mdTable text-center">
                                    <span
                                      className={clsx("tb-sub", item?.numberOfShortlisted ? styles.greenColor : "")}
                                    >
                                      {item?.numberOfShortlisted ? item?.numberOfShortlisted : 0}
                                    </span>
                                  </td>
                                  <td className="mdTable text-center ">
                                    <span className={clsx("tb-sub", item?.numberOfUnlocks ? styles.greenColor : "")}>
                                      {item?.numberOfUnlocks ? item?.numberOfUnlocks : 0}
                                    </span>
                                  </td>
                                  <td className="mdTable text-center ">
                                    <span
                                      className={clsx("tb-sub", item?.noOfInterviewsScheduled ? styles.greenColor : "")}
                                    >
                                      {item?.noOfInterviewsScheduled ? item?.noOfInterviewsScheduled : 0}
                                    </span>
                                  </td>
                                  <td className="mdTable text-center">
                                    <span className={clsx("tb-sub", item?.numberOfRejected ? styles.greenColor : "")}>
                                      {item?.numberOfRejected ? item?.numberOfRejected : 0}
                                    </span>
                                  </td>
                                  <td className="mdTable text-center">
                                    <span className={clsx("tb-sub")}>
                                      {item?.lastConnectedDate
                                        ? moment(item.lastConnectedDate)?.tz("Africa/Cairo")?.format("DD MMM YY h:mm A")
                                        : ""}
                                    </span>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>
                                <div className="text-center d-flex flex-column">
                                  <span className="text-silent">No List found</span>
                                  <span
                                    onClick={() => {
                                      setCurrentPage(1);
                                      setSearchText("");
                                      setOnSearchTextStatus(!onSearchTextStatus);
                                    }}
                                    style={{ color: "dodgerblue", cursor: "pointer" }}
                                  >
                                    Reset It
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </Table>
                  </div>
                ) : null}
                <div className="card-inner">
                  {actionByHrList.length > 0 ? (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  ) : (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ActionByHr;
